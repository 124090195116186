// VIDEOLOADER
(async () => {
  const { accountId, livePlayerId, vodPlayerId } =
    (window.tv2 && window.tv2.brightcove) || {};

  if (!accountId && (!livePlayerId || !vodPlayerId)) {
    console.error('missing configuration for the brightcove player', {
      accountId,
      livePlayerId,
      vodPlayerId,
    });

    return;
  }

  const videoElements = Array.prototype.slice.apply(
    window.document.querySelectorAll(
      '.tc_video__video:not(.tc_video__video--preview)',
    ),
  );

  if (videoElements.length) {
    // We may require more than one player script (live and vod)
    // in order to only load each player once, we willl loop through the
    // video elements on the page, and create a list of players to load
    const scripts = [];

    // get video js elements, with side effects
    const videoJsElements = videoElements
      .map((elem) => {
        if (
          !elem.querySelector(
            ':not(.tc_video__videoloop):not([data-vanilla="true"])',
          )
        ) {
          return;
        }
        const videoJsElem = elem.querySelector('video, video-js');
        const live = elem.getAttribute('data-live') === 'true';
        const playerId = live ? livePlayerId : vodPlayerId;

        // add required attributes
        videoJsElem.setAttribute('data-account', accountId);
        videoJsElem.setAttribute('data-player', playerId);

        // Set listener on close button
        const closeButton = elem.querySelector('.tc_video__close');
        if (closeButton) {
          closeButton.addEventListener('click', () => {
            elem.classList.add('tc_video__video--hide');
          });
        }

        // add script to scripts array
        const scriptPath = `https://players.brightcove.net/${accountId}/${playerId}_default/index.min.js`;
        if (!scripts.includes(scriptPath)) {
          scripts.push(scriptPath);
        }

        return videoJsElem;
      })
      .filter(Boolean);

    // Load all the scripts
    const scriptP = scripts.map(
      (script) =>
        new Promise((res) => {
          window.tv2.utils.loadScript(script, () => res());
        }),
    );
    await Promise.all(scriptP);

    // initiate all the video js elems
    videoJsElements.forEach((videoJsElem) => {
      const videoId = videoJsElem.getAttribute('id');
      let player;
      try {
        // Player may already be loaded, so we try to get it
        // eslint-disable-next-line no-undef
        player = videoJsElem.player || videojs.getPlayer(videoId);
      } catch (_) {
        // noop
      }
      if (!player) {
        // if not, we create it
        // eslint-disable-next-line no-undef
        player = bc(videoJsElem);
      }

      // publish an instance ready event to be picked up by eg. the carousel
      window.tv2messages.publish(`brightcovePlayerInstance:${videoId}`, player);
    });
  }
})();
